import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import AppContext from '../../../../Store/AppContext';
import './codingassessment.css';
import CountdownTimer from '../../../Common/CountdownTimer/CountdownTimer';
import parse from 'html-react-parser';
import Editor from "@monaco-editor/react";
import { useNavigate } from '@reach/router';
import { Dropdown, Spinner } from 'react-bootstrap';
import { Language } from '../../ClassLab/CodeEditor/Language';
import { ICN_ARROW_DOWN, ICN_DOWNLOAD, ICN_FULL_SCREEN, ICN_PLAY, ICN_STAR_HALF, ICN_BACK } from '../../../Common/Icon';
import OutputIcon from '@mui/icons-material/Output';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from "react-tooltip";
import DynamicTable from '../../../Common/DynamicTable/DynamicTable';
import RestService from '../../../../Services/api.service';
// import useToast from '../../../../Store/ToastHook';
import { CustomToggle } from '../../../../Services/MethodFactory';
import axios from 'axios';
import GLOBELCONSTANT from '../../../../Constant/GlobleConstant';
import ProgressBar from '../../../Common/ProgressBar/ProgressBar';
import LinearProgressBar from '../../../Layout/NewDashboardLearner/LinearProgressBar/LinearProgressBar';
import { LinearProgress } from '@mui/material';
import * as faceapi from "face-api.js";
import { IcnTimer } from '../../../Common/Icon';
import { BsModal } from '../../../Common/BsUtils';
import { CodingModal } from '../../../Common/CodingAssessmentModal';
import PauseCountdownTimer from '../../../Common/PauseCountdownTimer/PauseCountdownTimer';
import Split from 'react-split';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Submit from '../../Assessment/common/SubmitButton';
import { deviceType } from "react-device-detect";
import SkeletonLoader from '../../../Common/SkeletonLoader/SkeletonLoader';
import Webcam from "react-webcam";
import AWS from 'aws-sdk';
import Draggable from 'react-draggable';
import AlertDialog from '../../../Common/AlertDialog/AlertDialog';
import ToastMessage from '../../../../Store/NewToastHook';
import { ToastContainer } from 'react-toastify';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

const CodingAssessment = (props) => {

    const handle = useFullScreenHandle();

    const [codingAssessmentDetails, setCodingAssessmentDetails] = useState(props.location.state.codingAssessmentDetails);
    const [showInput, setShowInput] = useState(false);
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : '';
    const handleButtonClick = () => {
        setShowInput(!showInput);
    };
    // const videoConstraints = {
    //     width: 50,
    //     height: 50,
    //     facingMode: "user",
    // };




    const [configuration, setConfiguration] = useState({
        columns: {
            "testCaseName": {
                "title": "Test Case",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
            },
            "testCaseStatus": {
                "title": "Status",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                // render: (data) => user.role === ROLE.SUPERVISOR || user.role === ROLE.SUPER_USER ? data.noOfBatches
                //     : <p style={{ display: "none" }}></p>
            },
            "executionTime": {
                "title": "Run Time",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) => <>{`${data.executionTime} s`}</>

            },
            "memory": {
                "title": "Memory",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false
            },
            "score": {
                "title": "Score",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
            }
        },
        headerTextColor: '#454E50', // user can change table header text color

        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: false,
        clearSelection: false,
    });

    const [newColumn, setNewColumn] = useState();
    const [newColumn1, setNewColumn1] = useState();

    const [configuration1, setConfiguration1] = useState({
        columns: newColumn,
        headerTextColor: '#454E50', // user can change table header text color

        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration1.sortBy = sortKey;
            Object.keys(configuration1.columns).map(key => configuration1.columns[key].sortDirection = (key === sortKey) ? !configuration1.columns[key].sortDirection : false);
            configuration1.sortDirection = configuration1.columns[sortKey].sortDirection;
            setConfiguration1({ ...configuration1 });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: false,
        clearSelection: false,
    });

    const [configuration2, setConfiguration2] = useState({
        columns: newColumn1,
        headerTextColor: '#454E50', // user can change table header text color

        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration2.sortBy = sortKey;
            Object.keys(configuration2.columns).map(key => configuration2.columns[key].sortDirection = (key === sortKey) ? !configuration2.columns[key].sortDirection : false);
            configuration2.sortDirection = configuration2.columns[sortKey].sortDirection;
            setConfiguration2({ ...configuration2 });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: false,
        clearSelection: false,
    });

    const [steps, setSteps] = useState('INTRO');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [questionName, setQuestionName] = useState('')
    const [questionMark, setQuestionMark] = useState();
    const [questionDescription, setQuestionDescription] = useState('');
    const [codingQuestionId, setCodingQuestionId] = useState();
    const [databaseQuestion, setDatabaseQuestion] = useState();
    const [progress, setProgress] = useState({});
    const [completedQuestionNo, setCompletedQuestionNo] = useState(null);
    const [userAnswers, setUserAnswers] = useState('');
    const [codeByQuestionId, setCodeByQuestionId] = useState({});
    const [proctorDetails, setProctorDetails] = useState([...codingAssessmentDetails.proctoringDetails]);
    const [loadingCompile, setLoadingCompile] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [editorValue, setEditorValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [loadingStart, setLoadingStart] = useState(false);
    const [cameraAndMicPermission, setCameraAndMicPermission] = useState(false);

    const [codingSubmissionResponse, setCodingSubmissionResponse] = useState(null);
    const [compileSqlResponse, setCompileSqlResponse] = useState(null);

    const { spinner } = useContext(AppContext);
    const [inputData, setInputData] = useState('');
    const editorRef = useRef(null);
    const [lang, setLang] = useState(Language[0]);
    const [submitoutput, setSubmitoutput] = useState('');
    const [inputTab, setInputTab] = useState(true);
    const [codeTemplate, setCodeTemplate] = useState('');
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    // const Toast = useToast();

    const videoRef = useRef();
    const streamRef = useRef();

    const [labType, setLabType] = useState(props.location.state.type);
    const [codingAssessmentTimeOverInSeconds, setCodingAssessmentTimeOverInSeconds] = useState(props.location.state.codingAssessmentTimeOverInSeconds);
    const [codingAssessmentCheatingDetails, setCodingAssessmentCheatingDetails] = useState(props.location.state.codingAssessmentCheatingDetails);
    const [show, setShow] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [startAssessmentStatus, setStartAssessmentStatus] = useState('');
    const [fullScreenEnableCount, setFullScreenEnableCount] = useState(codingAssessmentCheatingDetails !== null ?
        codingAssessmentCheatingDetails?.fullScreenDetails?.count : 0);
    const [goBackStatus, setGoBackStatus] = useState(false);
    const [location, setLocation] = useState();
    const [counter, setCounter] = useState(15);
    const [changeindex, setchangeindex] = useState(1);

    const [trainingSid, setTrainingSid] = useState(props.location.state.trainingSid);
    const [sectionSid, setSectionSid] = useState(props.location.state.sectionSid);

    //multiple tab switching in coding assessment
    const [countab, setCountab] = useState(codingAssessmentCheatingDetails !== null ?
        codingAssessmentCheatingDetails.tabSwitchCount : 0);

    //screenshot
    //screenshot starts
    const webcamRef = useRef(null);
    const [progressScreenshot, setProgressScreenshot] = useState(0);
    const capture = async () => {
        try {
            const imageSrc = await getScreenshot();
            const blob = dataURLtoBlob(imageSrc);
            const newFile = getFileName(blob);
            uploadFile(newFile);

        } catch (error) {
            console.error("Error capturing photo:", error);
        }
    };

    const captureViolation = async (type) => {
        try {
            const imageSrc = await getScreenshot();
            const blob = dataURLtoBlob(imageSrc);
            const newFile = getFileNameViolation(blob, type);
            uploadFile(newFile);

        } catch (error) {
            console.error("Error capturing photo:", error);
        }
    };

    const getScreenshot = () => {
        return new Promise((resolve, reject) => {
            try {
                const imageSrc = webcamRef.current.getScreenshot();
                resolve(imageSrc);
            } catch (error) {
                reject(error);
            }
        });
    };

    const dataURLtoBlob = (dataurl) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const getFileName = (blob) => {
        const userSid = user?.sid;
        const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
        // const trainingSid = props.location.state.trainingSid;
        // const sectionSid = props.location.state.sectionSid;
        const timestamp = Date.now();
        const file = new File([blob], `ca_${userSid}_${codingAssessmentSid}_${sectionSid}_${trainingSid}_${timestamp}.jpeg`, {
            type: "image/jpeg",
        });
        return file;
    };

    const getFileNameViolation = (blob, type) => {
        const userSid = user?.sid;
        const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
        // const trainingSid = props.location.state.trainingSid;
        // const sectionSid = props.location.state.sectionSid;
        const timestamp = Date.now();
        const file = new File([blob], `ca_${userSid}_${codingAssessmentSid}_${sectionSid}_${trainingSid}_${timestamp}_${type}.jpeg`, {
            type: "image/jpeg",
        });

        return file;
    };
    const uploadFile = async (file) => {

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {


                setProgressScreenshot(Math.round((evt.loaded / evt.total) * 100));

            })
            .send((err, data) => {
                if (err) {

                    console.error(err);
                }

            });
    };


    const handleUserMedia = (stream) => {

        // Do something with the webcam stream
    };

    const handleUserMediaError = (error) => {
        ToastMessage({ type: "warning", message: 'To access the assessment, please grant permission for camera access.', time: 4000 });
        // Toast.warning({ message: 'To access the assessment, please grant permission for camera access.', time: 6000 });
        console.error('Error accessing webcam:', error);
        // Handle the error
    };


    //face detections
    const [faceDetections, setFaceDetections] = useState(0);
    const [faceviolationcount, setFaceviolationcount] = useState(codingAssessmentCheatingDetails !== null ?
        codingAssessmentCheatingDetails.faceDetectionCount : 0);


    // face detection methods starts
    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                streamRef.current = stream;
                loadModels();
            })
            .catch((err) => {
                console.error(err);
                // submitCodingAssessmentForGoBackAndCamera();
                ToastMessage({ type: "warning", message: 'To access the assessment, please grant permission for camera access.', time: 4000 });
                // Toast.warning({ message: 'To access the assessment, please grant permission for camera access.', time: 6000 });
            });
    };

    const loadModels = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        ]).then(() => {
            faceDetection();
        })
    };

    const faceDetection = async () => {
        setInterval(async () => {
            const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
            setFaceDetections(detections.length);

        }, 1000)
    }

    const stopProctorCamera = () => {
        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };

    useEffect(() => {
        // Check if newColumn is defined
        if (newColumn !== undefined) {
            // Update configuration1.columns when newColumn is available
            setConfiguration1(prevConfig => ({
                ...prevConfig,
                columns: newColumn,
            }));
        }
    }, [newColumn]);

    useEffect(() => {
        // Check if newColumn is defined
        if (newColumn1 !== undefined) {
            // Update configuration1.columns when newColumn is available
            setConfiguration2(prevConfig => ({
                ...prevConfig,
                columns: newColumn1,
            }));
        }
    }, [newColumn1]);

    useEffect(() => {
        if (faceDetections >= 2) {
            setShow(true);
            setFaceviolationcount(prevCount => prevCount + 1);
            ToastMessage({ type: "warning", message: 'Multiple Faces Detected', time: 4000 });
            // Toast.warning({ message: 'Multiple Faces Detected', time: 5000 });
        }

        // if (faceviolationcount >= 1000) {
        //     submitCodingAssessment();
        //     // navigate('/training/training-details');
        // }

    }, [faceDetections])

    // face detection methods starts ends

    const handleEditorDidMount = (editor, monaco) => {


        const editorValue = editor.getValue();

        editorRef.current = editor;
        // if (user.role !== "CONSUMER_LEARNER") {
        //     // Disable copy, cut, and paste
        //     editor.onKeyDown((event) => {
        //         const { keyCode, ctrlKey, metaKey } = event;
        //         if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
        //             event.preventDefault();
        //         }
        //     });
        //     editor.updateOptions({ contextmenu: false });
        // }

    }

    useEffect(() => {
        // Simulate an asynchronous update with a delay
        const delay = 2000; // 2 seconds in milliseconds

        // Set loading to true when changeindex changes
        setIsLoading(true);

        const timeoutId = setTimeout(() => {
            // Check if changeindex is not null and update the state variable
            if (changeindex !== null) {
                setIsLoading(false);
            }
        }, delay);

        // Cleanup function to clear the timeout in case the component unmounts or the effect is re-run
        return () => clearTimeout(timeoutId);
    }, [codingQuestionId, changeindex, lang]);
    // // Function to handle user's answer selection
    const handleCodeChange = (value) => {
        // Check if the compile entry exists
        const compileKey = `codingquestionId_${codingQuestionId}_compile_${lang.language}`;
        // localStorage.getItem(`codingquestionId_${changeindex}_compile_${lang.language}`)
        localStorage.setItem(compileKey, value);

        // Always update the language entry
        localStorage.setItem(`language_${codingQuestionId}_${lang.language}`, lang.language);
    };


    const handleNext = () => {
        if (currentIndex < codingAssessmentDetails.questionDetails.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setQuestionName(codingAssessmentDetails.questionDetails[currentIndex + 1].question);
            setQuestionMark(codingAssessmentDetails.questionDetails[currentIndex + 1].marks);
            setQuestionDescription(codingAssessmentDetails.questionDetails[currentIndex + 1].description);
            setCodingQuestionId(codingAssessmentDetails.questionDetails[currentIndex + 1].codingQuestionId);
            setDatabaseQuestion(codingAssessmentDetails.questionDetails[currentIndex + 1].databaseQuestion);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setQuestionName(codingAssessmentDetails.questionDetails[currentIndex - 1].question);
            setQuestionMark(codingAssessmentDetails.questionDetails[currentIndex - 1].marks);
            setQuestionDescription(codingAssessmentDetails.questionDetails[currentIndex - 1].description);
            setCodingQuestionId(codingAssessmentDetails.questionDetails[currentIndex - 1].codingQuestionId);
            setDatabaseQuestion(codingAssessmentDetails.questionDetails[currentIndex - 1].databaseQuestion);
        }
    };

    const handleQuestion = async (index, id) => {
        setCurrentIndex(index);
        setQuestionName(codingAssessmentDetails.questionDetails[index].question);
        setQuestionMark(codingAssessmentDetails.questionDetails[index].marks);
        setQuestionDescription(codingAssessmentDetails.questionDetails[index].description);
        setCodingQuestionId(codingAssessmentDetails.questionDetails[index].codingQuestionId);
        setDatabaseQuestion(codingAssessmentDetails.questionDetails[index].databaseQuestion);
        setSubmitoutput('');
        setCodingSubmissionResponse(null);
        setCompileSqlResponse(null);
        setShowInput(false);
        setInputData('');
        // const savedCode =  localStorage.getItem(`codingquestionId_${id}_compile`);
        // setUserAnswers(savedCode);
    };
    // useEffect(() => {
    //     // This effect will run whenever codingQuestionId or currentIndex changes

    //     setCodeByQuestionId(codingAssessmentDetails.questionDetails[mapcurrentIndex].codingQuestionId)
    //     if (mapcurrentIndex !== null) {
    //       // Perform the subsequent actions here using currentIndex
    //       // Example: Call the handleCodeChange function
    //   handleQuestion(mapcurrentIndex,codingQuestionId);
    //     }
    //   }, [codingQuestionId, mapcurrentIndex]);


    function getLabelForLanguage(languageToFind) {
        if (languageToFind !== undefined) {
            for (let i = 0; i < Language.length; i++) {
                if (Language[i].language === languageToFind) {
                    return Language[i];
                }
            }
        }
        return null; // Return null if the language is not found
    }

    //mark labs as complete
    const markCourseAsCompletedCode = () => {
        try {
            // const trainingSid = props.location.state.trainingSid;
            // const sectionSid = props.location.state.sectionSid;
            let payload = {
                "completedInDuration": 0,
                "totalDuration": 0
            }
            // spinner.show();
            RestService.markCourseAsCompletedCode(codingQuestionId, sectionSid, trainingSid, payload).then(
                response => {
                    if (response.status === 200) {
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markCourseAsCompletedCode()", err)
        }
    }


    //get code template
    const getCodeTemplate = (codeLanguage, codingId) => {
        try {
            const language = codeLanguage.language;
            RestService.getCodeTemplate(language, codingId).then(
                response => {
                    if (response.status === 200) {
                        setCodeTemplate(response.data);

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getCodeTemplate()", err)
        }
    }

    //coding assessment compile question 
    const codingAssessmentCompileQuestion = () => {
        try {
            setLoadingCompile(true);
            const language = lang.language;
            const versionIndex = lang.versionIndex;
            // const trainingSid = props.location.state.trainingSid;
            // const sectionSid = props.location.state.sectionSid ;
            let payload = {
                "codingAssessmentSid": codingAssessmentDetails.codingAssessmentSid,
                "codingQuestionId": codingQuestionId,
                // "languageCode": language,
                "sectionSid": sectionSid,
                // "stdin": inputData,
                "trainingSid": trainingSid,
                "userCode": editorRef.current.getValue(),
                // "versionIndex": versionIndex
            }
            if (!databaseQuestion) {
                payload.languageCode = language;
                payload.stdin = inputData;
                payload.versionIndex = versionIndex;
            }

            // spinner.show();
            RestService.codingAssessmentCompileQuestion(payload).then(
                response => {
                    if (response.status === 200) {
                        setLoadingCompile(false);
                        if (databaseQuestion) {
                            setSubmitoutput('');
                            let convertUserResponseToObject = {};
                            const convertCorrectResponseToObject = {};
                            let userResponseColumn = [];
                            if ('details' in response.data?.userResponse) {
                                userResponseColumn = Object.keys(response.data?.userResponse?.details).map(i => ({
                                    [`${i}`]: {
                                        "title": `${i}`,
                                        "sortDirection": null,
                                        "sortEnabled": false,
                                        isSearchEnabled: false,
                                    }
                                }))
                            }
                            else {
                                if ('results' in response.data.userResponse) {
                                    userResponseColumn = Object.keys(response.data.userResponse?.results[0]).map(i => ({
                                        [`${i}`]: {
                                            "title": `${i}`,
                                            "sortDirection": null,
                                            "sortEnabled": false,
                                            isSearchEnabled: false,
                                        }
                                    }));
                                }
                                else {
                                    userResponseColumn = []
                                }

                            }
                            const correctResponseColumn = Object.keys(response.data.correctResponse.results[0]).map(i => ({
                                [`${i}`]: {
                                    "title": `${i}`,
                                    "sortDirection": null,
                                    "sortEnabled": false,
                                    isSearchEnabled: false,
                                }
                            }));
                            if ('details' in response.data?.userResponse) {
                                userResponseColumn.forEach((item) => {
                                    Object.assign(convertUserResponseToObject, item);
                                });
                            }
                            else {
                                if ('results' in response.data.userResponse) {
                                    for (let i = 0; i < userResponseColumn.length; i++) {
                                        Object.assign(convertUserResponseToObject, userResponseColumn[i]);
                                    }
                                } else {
                                    convertUserResponseToObject = {}
                                }
                            }
                            for (let i = 0; i < correctResponseColumn.length; i++) {
                                Object.assign(convertCorrectResponseToObject, correctResponseColumn[i]);
                            }
                            setNewColumn(convertUserResponseToObject);

                            setNewColumn1(convertCorrectResponseToObject);
                            setLoadingCompile(false);
                            setCompileSqlResponse(response.data);
                        }
                        else {
                            setSubmitoutput(response.data);
                            setCodingSubmissionResponse(null);
                            setCompileSqlResponse(null);
                        }
                        setInputTab(false);
                        // setLoadingCompile(false);

                        localStorage.setItem(`codingquestionId_${codingQuestionId}_compile`, editorRef.current.getValue());
                        localStorage.setItem(`language_${codingQuestionId}`, lang.language);
                    }
                },
                err => {
                    // spinner.hide();
                    setLoadingCompile(false);
                }
            ).finally(() => {
                // spinner.hide();
            });
        } catch (err) {
            console.error("error occur on codingAssessmentCompileQuestion()", err)
        }
    }


    //coding assessment submit question
    const codingAssessmentSubmitQuestion = () => {
        try {
            // setSpinners(true);
            setLoadingSubmit(true);
            const language = lang.language;
            const versionIndex = lang.versionIndex
            // const trainingSid = props.location.state.trainingSid;
            // const sectionSid = props.location.state.sectionSid;
            let payload = {
                "codingAssessmentSid": codingAssessmentDetails.codingAssessmentSid,
                "codingQuestionId": codingQuestionId,
                // "languageCode": language,
                "sectionSid": sectionSid,
                // "stdin": inputData,
                "trainingSid": trainingSid,
                "userCode": editorRef.current.getValue(),
                // "versionIndex": versionIndex
            }
            if (!databaseQuestion) {
                payload.languageCode = language;
                payload.stdin = inputData;
                payload.versionIndex = versionIndex;
            }
            // spinner.show();
            RestService.codingAssessmentSubmitQuestion(payload).then(
                response => {
                    if (response.status === 200) {
                        ToastMessage({ type: "success", message: 'Code Submitted Successfully', time: 2000 });
                        setLoadingSubmit(false);
                        // Toast.success({ message: 'Code Submitted Successfully', time: 2000 });
                        setSubmitoutput('');
                        setCompileSqlResponse(null);
                        if (databaseQuestion) {
                            setSubmitoutput('');
                            let convertUserResponseToObject = {};
                            const convertCorrectResponseToObject = {};
                            let userResponseColumn = [];
                            if ('details' in response.data?.databaseQuestionResponse?.userResponse) {
                                userResponseColumn = Object.keys(response.data.databaseQuestionResponse?.userResponse?.details).map(i => ({
                                    [`${i}`]: {
                                        "title": `${i}`,
                                        "sortDirection": null,
                                        "sortEnabled": false,
                                        isSearchEnabled: false,
                                    }
                                }));
                            }
                            else {
                                if ('results' in response.data?.databaseQuestionResponse?.userResponse) {
                                    userResponseColumn = Object.keys(response.data.databaseQuestionResponse.userResponse.results[0]).map(i => ({
                                        [`${i}`]: {
                                            "title": `${i}`,
                                            "sortDirection": null,
                                            "sortEnabled": false,
                                            isSearchEnabled: false,
                                        }
                                    }));
                                }
                                else {
                                    userResponseColumn = [];
                                }
                            }

                            const correctResponseColumn = Object.keys(response.data.databaseQuestionResponse.correctResponse.results[0]).map(i => ({
                                [`${i}`]: {
                                    "title": `${i}`,
                                    "sortDirection": null,
                                    "sortEnabled": false,
                                    isSearchEnabled: false,
                                }
                            }));

                            if ('details' in response.data?.databaseQuestionResponse?.userResponse) {
                                userResponseColumn.forEach((item) => {
                                    Object.assign(convertUserResponseToObject, item);
                                });
                            }
                            else {
                                if ('results' in response.data?.databaseQuestionResponse?.userResponse) {
                                    for (let i = 0; i < userResponseColumn.length; i++) {
                                        Object.assign(convertUserResponseToObject, userResponseColumn[i]);
                                    }
                                }
                                else {
                                    convertUserResponseToObject = {};
                                }
                            }
                            for (let i = 0; i < correctResponseColumn.length; i++) {
                                Object.assign(convertCorrectResponseToObject, correctResponseColumn[i]);
                            }

                            setNewColumn(convertUserResponseToObject);
                            setNewColumn1(convertCorrectResponseToObject);
                        }
                        setCodingSubmissionResponse(response.data);
                        setProgress((prevUserAnswers) => {
                            const updatedProgress = { ...prevUserAnswers };
                            updatedProgress[codingQuestionId] = codingQuestionId
                            return updatedProgress;
                        });
                        setCompletedQuestionNo((prevUserAnswers) => {
                            const updatedProgress = { ...prevUserAnswers };
                            updatedProgress[currentIndex + 1] = currentIndex + 1;
                            return updatedProgress;
                        });
                        // setSpinners(false);
                        setInputTab(false);
                        localStorage.setItem(`codingquestionId_${codingQuestionId}`, editorRef.current.getValue());
                        localStorage.setItem(`language_${codingQuestionId}`, lang.language);
                    }
                },
                err => {
                    // spinner.hide();
                    // setSpinners(false);
                    setLoadingSubmit(false);
                    ToastMessage({ type: "error", message: 'Try Again', time: 2000 });
                    // Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                // spinner.hide();
            });
        } catch (err) {
            console.error("error occur on codingAssessmentSubmitQuestion()", err)
        }
    }

    //start assessment
    const startCodingAssessment = async () => {
        // const permission = await handlePermissionRequest();
        // if (permission) {
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            // const sectionSid = props.location.state.sectionSid;
            // const trainingSid = props.location.state.trainingSid;

            let payload = {
                "codingAssessmentSid": codingAssessmentSid,
                "sectionSid": sectionSid,
                "locationDetails": {
                    "ip": location.ip,
                    "latitude": location.latitude,
                    "location": location.city,
                    "longitude": location.longitude,
                },
                "trainingSid": trainingSid
            }
            if (deviceType === "browser") {
                payload.locationDetails.system = "Desktop"
            }
            else if (deviceType === "mobile") {
                payload.locationDetails.system = "Mobile"
            }
            else {
                payload.locationDetails.system = "Tablet"
            }

            spinner.show();
            RestService.startCodingAssessment(payload).then(
                response => {
                    if (response.status === 200) {

                        // Toast.success({ message: 'Assessment Started Successfully', time: 2000 });
                        if (Object.keys(response.data).length === 0) {
                            localStorage.setItem("startAssessmentStatus", "Assessment Started Successfully");
                        }
                        else if (codingAssessmentSid in response.data) {
                            localStorage.setItem("startAssessmentStatus", "Assessment Started Successfully");
                        }
                        else {
                            // const jsonParse = JSON.parse(response.data);
                            Object.keys(response.data).map(codingQuestionId => {

                                const item = JSON.parse(response.data[codingQuestionId]); // Access the object using codingQuestionId key

                                localStorage.setItem(`codingquestionId_${codingQuestionId}`, item.code);
                                localStorage.setItem(`codingquestionId_${codingQuestionId}_${item.language}`, item.code);
                                localStorage.setItem(`language_${codingQuestionId}_${item.language}`, item.language);
                                localStorage.setItem("startAssessmentStatus", "Assessment Started Successfully");
                                getCodingItemCount();

                            });
                        }

                        setStartAssessmentStatus('Assessment Started Successfully');
                        if (proctorDetails.includes('ENABLE_FULL_SCREEN') && user.role !== "CONSUMER_LEARNER") {
                            setShowFullScreen(true);
                        }
                        // if (proctorDetails.includes('MULTIPLE_FACE_DETECTIONS') && user.role !== "CONSUMER_LEARNER") {
                        //     startVideo();
                        // }

                    }
                },
                err => {
                    spinner.hide();
                    ToastMessage({ type: "error", message: 'Try Again', time: 2000 });
                    // Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on startCodingAssessment()", err)
        }
        // }
        // else {
        //     alert("To access the assessment, please grant permission for camera and mic access.");
        // }

    }

    //submit coding assessment
    const submitCodingAssessment = () => {
        setLoadingStart(true);
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            // const sectionSid = props.location.state.sectionSid;
            // const trainingSid = props.location.state.trainingSid;
            const jsonStringPressKey = JSON.stringify(presskey);

            let payload = {
                "cameraEnabled": true,
                "micEnabled": true,
                "tabSwitchCount": countab,
                "codingAssessmentSid": codingAssessmentSid,
                "sectionSid": sectionSid,
                "trainingSid": trainingSid,
                "specialKeyDetails": jsonStringPressKey,
                "faceDetectionCount": faceviolationcount
            }
            if (countMouseOutside === 0) {
                payload.mouseAlwaysInWindowDetails = {
                    "count": 0,
                    "enabled": true
                }
            }
            else {
                payload.mouseAlwaysInWindowDetails = {
                    "count": countMouseOutside,
                    "enabled": false
                }
            }
            if (fullScreenEnableCount === 0) {
                payload.fullScreenDetails = {
                    "count": 0,
                    "enabled": true
                }
            }
            else {
                payload.fullScreenDetails = {
                    "count": fullScreenEnableCount,
                    "enabled": false
                }
            }
            // spinner.show();
            RestService.submitCodingAssessment(payload).then(
                response => {
                    if (response.status === 200) {
                        // stopProctorCamera();
                        if (showFullScreen === false) {
                            if (proctorDetails.includes('ENABLE_FULL_SCREEN') && user.role !== "CONSUMER_LEARNER") {
                                enableFullscreen();
                            }
                        }
                        ToastMessage({ type: "success", message: 'Assessment Submitted Successfully', time: 3000 });
                        // Toast.success({ message: 'Assessment Submitted Successfully', time: 3000 });
                        if (faceviolationcount >= 1000) {
                            markViolation();
                            // Toast.warning({ message: 'Multiple Faces Detected', time: 4000 });  
                        }
                        if (countab >= 1000) {
                            markViolationCodingAssessment();
                            // Toast.warning({ message: 'Tab Switch Detected', time: 4000 });
                        }
                        // Get all keys from localStorage
                        const localStorageKeys = Object.keys(localStorage);
                        // Iterate over the dynamic keys and remove corresponding items from localStorage
                        localStorageKeys.map(key => {
                            if (key.includes('codingquestionId') || key.includes('language')) {

                                localStorage.removeItem(key);
                                return null; // You need to return something when using map
                            }
                        });

                        localStorage.removeItem('startAssessmentStatus');
                        localStorage.removeItem('end_date');
                        //}
                        // markCourseAsCompletedCode();
                        setTimeout(() => {
                            setLoadingStart(false);
                            navigate('/training/training-details');
                        }, 1500);

                    }
                },
                err => {
                    setLoadingStart(false);
                    ToastMessage({ type: "error", message: 'Try Again', time: 2000 });
                    // Toast.error({ message: 'Try Again', time: 2000 });
                }
            )
            // .finally(() => {
            //     setLoadingStart(false);
            // });
        } catch (err) {
            setLoadingStart(false);
            console.error("error occur on submitCodingAssessment()", err)
        }
    }

    //submit coding assessment for goback button an camera permission not allowed
    const submitCodingAssessmentForGoBackAndCamera = () => {
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            // const sectionSid = props.location.state.sectionSid;
            // const trainingSid = props.location.state.trainingSid;
            const jsonStringPressKey = JSON.stringify(presskey);

            let payload = {
                "cameraEnabled": true,
                "micEnabled": true,
                "tabSwitchCount": countab,
                "codingAssessmentSid": codingAssessmentSid,
                "sectionSid": sectionSid,
                "trainingSid": trainingSid,
                "specialKeyDetails": jsonStringPressKey,
                "faceDetectionCount": faceviolationcount
            }
            if (countMouseOutside === 0) {
                payload.mouseAlwaysInWindowDetails = {
                    "count": 0,
                    "enabled": true
                }
            }
            else {
                payload.mouseAlwaysInWindowDetails = {
                    "count": countMouseOutside,
                    "enabled": false
                }
            }
            if (fullScreenEnableCount === 0) {
                payload.fullScreenDetails = {
                    "count": 0,
                    "enabled": true
                }
            }
            else {
                payload.fullScreenDetails = {
                    "count": fullScreenEnableCount,
                    "enabled": false
                }
            }
            spinner.show();
            RestService.submitCodingAssessment(payload).then(
                response => {
                    if (response.status === 200) {
                        // stopProctorCamera();
                        // Toast.success({ message: 'Assessment Submitted Successfully', time: 3000 });
                        if (faceviolationcount >= 1000) {
                            markViolation();
                            // Toast.warning({ message: 'Multiple Faces Detected', time: 4000 });  
                        }
                        if (countab >= 1000) {
                            markViolationCodingAssessment();
                            // Toast.warning({ message: 'Tab Switch Detected', time: 4000 });
                        }
                        // Get all keys from localStorage
                        const localStorageKeys = Object.keys(localStorage);
                        // Iterate over the dynamic keys and remove corresponding items from localStorage
                        localStorageKeys.map(key => {
                            if (key.includes('codingquestionId') || key.includes('language')) {

                                localStorage.removeItem(key);
                                return null; // You need to return something when using map
                            }
                        });

                        localStorage.removeItem('startAssessmentStatus');
                        localStorage.removeItem('end_date');
                        navigate('/training/training-details');
                    }
                },
                err => {
                    spinner.hide();
                    ToastMessage({ type: "error", message: 'Try Again', time: 2000 });
                    // Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on submitCodingAssessmentForGoBackAndCamera()", err)
        }
    }

    // mark violation for assessment labs
    const markViolation = () => {
        try {
            // let trainingSid = props.location.state.trainingSid;
            let assessmentType = labType;
            let payload = {
                "trainingSid": trainingSid,
                "violationCode": "Multiple faces were detected for 5 times.",
                "labcodingAssessmentSidId": codingAssessmentDetails.codingAssessmentSid
            }

            RestService.markViolation(assessmentType, payload).then(
                response => {

                    if (response.status === 200) {
                    }
                },
                err => {
                    console.error(err);
                }
            )
        } catch (err) {
            console.error("error occur on markViolation()", err)
        }
    }

    // mark violation for coding assessment
    const markViolationCodingAssessment = () => {
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            // let trainingSid = props.location.state.trainingSid;
            let assessmentType = labType;
            let payload = {
                "labId": codingAssessmentSid,
                "trainingSid": trainingSid,
                "violationCode": "More than 5 tab switches detected"
            }
            RestService.markViolation(assessmentType, payload).then(
                response => {

                    if (response.status === 200) {
                        // navigate("/training/training-details");
                    }
                },
                err => {
                    console.error(err);
                }
            )
        } catch (err) {
            console.error("error occur on markViolationCodingAssessment()", err)
        }
    }

    //send time in seconds after 60 seconds for time spent
    const trackAssessmentTime = () => {
        try {
            // const trainingSid = props.location.state.trainingSid;
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            const sectionSid = props.location.state.sectionSid;

            let timestamp = new Date(localStorage.getItem("end_date") - Date.now());
            let splitMinutes = timestamp.toUTCString().match(/\d{2}:\d{2}:\d{2}/)[0];
            let minutes = Number(splitMinutes.split(':')[0])
                * 60 + Number(splitMinutes.split(':')[1]);
            const timeSpentInSeconds = (codingAssessmentDetails.duration - minutes) * 60;

            RestService.trackAssessmentTime(codingAssessmentSid, sectionSid, timeSpentInSeconds, trainingSid).then(
                response => {

                    if (response.status === 200) {
                        // navigate("/training/training-details");
                    }
                },
                err => {
                    console.error(err);
                }
            )
        } catch (err) {
            console.error("error occur on trackAssessmentTime()", err)
        }
    }

    //trigger when violation occurs
    const trackAssessmentTimeViolation = () => {
        try {

            // const trainingSid = props.location.state.trainingSid;
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            const sectionSid = props.location.state.sectionSid;


            let timestamp = new Date(localStorage.getItem("end_date") - Date.now());
            let splitMinutes = timestamp.toUTCString().match(/\d{2}:\d{2}:\d{2}/)[0];
            let minutes = Number(splitMinutes.split(':')[0])
                * 60 + Number(splitMinutes.split(':')[1]);
            const timeSpentInSeconds = (codingAssessmentDetails.duration - minutes) * 60;
            const jsonStringPressKey = JSON.stringify(presskey);


            let payload = {
                "cameraEnabled": true,
                "micEnabled": true,
                "tabSwitchCount": countab,
                "specialKeyDetails": jsonStringPressKey,
                "faceDetectionCount": faceviolationcount
            }
            if (countMouseOutside === 0) {
                payload.mouseAlwaysInWindowDetails = {
                    "count": 0,
                    "enabled": true
                }
            }
            else {
                payload.mouseAlwaysInWindowDetails = {
                    "count": countMouseOutside,
                    "enabled": false
                }
            }
            if (fullScreenEnableCount === 0) {
                payload.fullScreenDetails = {
                    "count": 0,
                    "enabled": true
                }
            }
            else {
                payload.fullScreenDetails = {
                    "count": fullScreenEnableCount,
                    "enabled": false
                }
            }

            RestService.trackAssessmentTime(codingAssessmentSid, sectionSid, timeSpentInSeconds, trainingSid, payload).then(
                response => {

                    if (response.status === 200) {

                        // navigate("/training/training-details");
                    }
                },
                err => {
                    console.error(err);
                }
            )
        } catch (err) {
            console.error("error occur on trackAssessmentTime()", err)
        }
    }

    //tab switch starts 
    useEffect(() => {
        if ((startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage)
            && proctorDetails.includes('TAB_SWITCH') && user.role !== "CONSUMER_LEARNER") {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    // Tab is visible

                } else {
                    // Tab is hidden

                    setCountab(prevCount => prevCount + 1);
                    ToastMessage({ type: "warning", message: 'Tab Switch Detected', time: 3000 });

                    // Toast.warning({ message: 'Tab Switch Detected', time: 5000 });


                }
            }

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, [startAssessmentStatus, countab]);

    // useEffect(() => {
    //     // const violationMessage = "More than 5 tab switches detected";
    //     if (countab >= 1000) {
    //         submitCodingAssessment();

    //     }
    // }, [countab])

    //tab switch starts 

    // useEffect(() => {

    //     if (localStorage.getItem("startAssessmentStatus")) {
    //         if (proctorDetails.includes('MULTIPLE_FACE_DETECTIONS') && user.role !== "CONSUMER_LEARNER") {
    //             startVideo();
    //         }
    //     }
    // }, []);

    // useEffect(() => {
    //     if (steps === 'QUESTION' && !databaseQuestion) {
    //         getCodeTemplate(lang);
    //     }

    // }, [steps]);

    const getLocation = () => {
        fetch("https://api.ipify.org/?format=json")
            .then(response => {
                return response.json();
            })
            .then(data1 => {
                return fetch(`https://ipapi.co/${data1.ip}/json/`);
            })
            .then(response => {
                return response.json();
            })
            .then(data2 => {
                setLocation(data2);

            })
            .catch(error => {
                console.error(error);
            });
    }

    //go back to training page
    const handleGoToTrainingDetails = () => {

        if (startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage) {
            const response = window.confirm("Are you sure you want to end this assessment?");
            if (response) {
                // setGoBackStatus(true);
                submitCodingAssessment();
                // Toast.success({ message: 'Assessment Submitted Successfully', time: 4000 });
                // enableFullscreen();

            }

        }

        else {
            navigate("/training/training-details");
        }
    }
    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent the default context menu behavior
    };

    const setupRightClickDisable = () => {
        document.addEventListener('contextmenu', handleContextMenu);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    };

    useEffect(() => {
        setupRightClickDisable();
        if (startAssessmentStatus.length === 0 || localStorage.getItem('startAssessmentStatus') === null) {
            getLocation();
        }
    }, []); // The empty dependency array ensures that the effect runs only once when the component mounts
    // useEffect(() => {
    //     getLocation();
    // }, [])

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1500);
    }, [counter]);

    // const disableCopy = useRef((e) => e.preventDefault());
    // const disableCut = useRef((e) => e.preventDefault());
    // const disablePaste = useRef((e) => e.preventDefault());

    // useEffect(() => {
    //     if (proctorDetails.includes('DISABLE_COPY_PASTE') && user.role !== "CONSUMER_LEARNER") {
    //         // Attach event listeners to the document
    //         document.addEventListener('copy', disableCopy.current);
    //         document.addEventListener('cut', disableCut.current);
    //         document.addEventListener('paste', disablePaste.current);

    //         // Detach event listeners when the component is unmounted
    //         return () => {
    //             document.removeEventListener('copy', disableCopy.current);
    //             document.removeEventListener('cut', disableCut.current);
    //             document.removeEventListener('paste', disablePaste.current);
    //         };
    //     }

    // }, []);
    //sachin
    useEffect(() => {
        setEditorValue(() => {
            const storedCompile = localStorage.getItem(`codingquestionId_${codingQuestionId}_compile_${lang.language}`);
            const storedCode = localStorage.getItem(`codingquestionId_${codingQuestionId}_${lang.language}`);
            return (
                storedCompile !== null
                    ? storedCompile
                    : storedCode !== null
                        ? storedCode
                        : (!databaseQuestion
                            ? codeTemplate
                            : ' '
                        )
            );
        });
    }, [codingQuestionId, codeTemplate, changeindex, lang]);


    const getSpecialKeyDetails = (details) => {
        if (!details) {
            return { alt: 0, control: 0, tab: 0 };
        }
        try {
            return JSON.parse(details);
        } catch {
            return { alt: 0, control: 0, tab: 0 };
        }
    };

    const specialKeyDetails = getSpecialKeyDetails(codingAssessmentCheatingDetails?.specialKeyDetails);


    //capture key for screen starts
    const [presskey, setPressKey] = useState({
        alt: specialKeyDetails.alt ?? 0,
        control: specialKeyDetails.control ?? 0,
        tab: specialKeyDetails.tab ?? 0,
    });

    const [isMouseOutside, setIsMouseOutside] = useState(false);
    const [countMouseOutside, setCountMouseOutside] = useState(codingAssessmentCheatingDetails !== null ?
        codingAssessmentCheatingDetails?.mouseAlwaysInWindowDetails?.count : 0);

    const handleKeyDown = (event) => {
        if (proctorDetails.includes('SPECIAL_KEYS') && user.role !== "CONSUMER_LEARNER") {
            // Check for Tab key
            if (event.which === 9) {
                setPressKey({
                    ...presskey,
                    "tab": presskey.tab + 1
                });

                // Handle Tab key press
            }

            // Check for Alt key
            if (event.which === 18) {
                setPressKey({
                    ...presskey,
                    "alt": presskey.alt + 1
                });



                // Handle Alt key press
            }
            // Check for right Alt key
            // if (event.key === 'AltGraph') {
            //     setPressKey({
            //         ...presskey,
            //         "alt": presskey.alt + 1
            //     });
            //     // Handle right Alt key press
            // }
            // Check for Control key
            if (event.which === 17) {
                setPressKey({
                    ...presskey,
                    "control": presskey.control + 1
                });
            }
        }

    };

    // const toggleFullScreen = () => {
    //     const elem = document.documentElement;

    //     if (isFullScreen) {
    //     if (document.exitFullscreen) {
    //         document.exitFullscreen();
    //         // setShowFullScreen(true);
    //         setFullScreenEnableCount(prevCount => prevCount + 1);
    //         setPressKey({
    //             ...presskey,
    //             "escape": presskey.escape + 1
    //         });
    //     }
    //     } 
    //     else {
    //         if (elem.requestFullscreen) {
    //             elem.requestFullscreen();
    //             setShowFullScreen(false);
    //         }
    //     }

    //     setIsFullScreen(!isFullScreen);
    // };
    useEffect(() => {

        // if (startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage) {
        if ((startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage) &&
            proctorDetails.includes('MOUSE_IN_ASSESSMENT') && user.role !== "CONSUMER_LEARNER") {

            const handleMouseEnter = () => {
                setIsMouseOutside(false);
            };

            const handleMouseLeave = () => {
                setCountMouseOutside(prevCount => prevCount + 1);
                setIsMouseOutside(true);
                ToastMessage({ type: "warning", message: 'Mouse Outside Assessment Detected', time: 3000 });
                // Toast.warning({ message: 'Mouse Outside Assessment Detected', time: 5000 });
            };

            // Add event listeners
            document.addEventListener('mouseenter', handleMouseEnter);
            document.addEventListener('mouseleave', handleMouseLeave);

            // Remove event listeners on component unmount
            return () => {
                document.removeEventListener('mouseenter', handleMouseEnter);
                document.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
        // }

    }, [startAssessmentStatus]);

    const enableFullscreen = () => {
        const element = document.documentElement;

        if (
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
        ) {
            // If already in fullscreen, exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            // If not in fullscreen, request fullscreen
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    };

    const handleFullscreenChange = () => {
        const isFullscreen =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement;

        setShowFullScreen(!isFullscreen);
        // If exiting fullscreen, increment the disable count
        if (!isFullscreen) {
            setFullScreenEnableCount((prevCount) => prevCount + 1);
        }
    };

    useEffect(() => {

        //disable ctrl shift i 
        const disableConsole = (event) => {
            if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
                event.preventDefault();
            }
            else if (event.metaKey && event.altKey && event.keyCode === 73) {
                event.preventDefault();
            }
        };

        const handleKeyPress = (event) => {
            // Check if the pressed keys are Control+R or Control+Shift+R
            if ((event.ctrlKey || event.metaKey) && (event.key === 'r' || event.key === 'R')) {
                event.preventDefault(); // Prevent the default behavior (page refresh)
            }
        };

        //restrict browser back button
        const handlePopstate = () => {
            // Redirect the user if they try to navigate back to the restricted page
            if (window.location.pathname === '/training/training-details') {
                navigate('/coding');
            }
        };
        window.addEventListener('keydown', disableConsole);
        // Listen for the 'popstate' event
        // if (user.role !== "CONSUMER_LEARNER") {
        window.addEventListener('popstate', handlePopstate);
        // }
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);
        // Attach the event listener when the component mounts
        if (proctorDetails.includes('DISABLE_SCREEN_REFRESH_KEYS') && user.role !== "CONSUMER_LEARNER") {
            document.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
            window.removeEventListener('keydown', disableConsole);
            // if (user.role !== "CONSUMER_LEARNER") {
            window.removeEventListener('popstate', handlePopstate);
            // }
            if (proctorDetails.includes('DISABLE_SCREEN_REFRESH_KEYS') && user.role !== "CONSUMER_LEARNER") {
                document.removeEventListener('keydown', handleKeyPress);
            }
        };
    }, []);

    // useEffect(() => {
    //     // Listen for the Escape key to close the modal
    //     const handleKeyPress = (e) => {
    //         if (e.key === 'Escape' && showFullScreen) {
    //             setPressKey({
    //                 ...presskey,
    //                 "escape": presskey.escape + 1
    //             });
    //             // setShowFullScreen(!showFullScreen);
    //         }
    //     };

    //     document.addEventListener('keydown', handleKeyPress);

    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, [showFullScreen]);

    useEffect(() => {
        if (startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage) {

            trackAssessmentTimeViolation();
            // if (faceviolationcount) {
            //     captureViolation('FD');
            // }
        }
    }, [countab, faceviolationcount, fullScreenEnableCount, presskey, countMouseOutside]);

    useEffect(() => {
        if (startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage) {
            const intervalId = setInterval(() => {

                trackAssessmentTime();
            }, 60000); // Set the interval to 60000 milliseconds (60 seconds)
            return () => clearInterval(intervalId);
        }

    }, []);

    // useEffect(() => {
    //     if ((startAssessmentStatus.length !== 0 || `startAssessmentStatus` in localStorage) && user.role !== "CONSUMER_LEARNER") {
    //         let intervalId;
    //         if (webcamRef !== null) {
    //             intervalId = setInterval(() => {
    //                 capture();
    //             }, 40000);
    //         }

    //         return () => clearInterval(intervalId);
    //     }

    // }, [startAssessmentStatus, localStorage]);


    // useEffect(() => {
    //     if (!showFullScreen && `startAssessmentStatus` in localStorage) {
    //         enableFullscreen();
    //     }
    // }, [showFullScreen]);

    // const spendTime = () => {
    //     let timestamp = new Date(localStorage.getItem("end_date") - Date.now());
    //     let splitMinutes = timestamp.toUTCString().match(/\d{2}:\d{2}:\d{2}/)[0];
    //     let minutes = Number(splitMinutes.split(':')[0])
    //         * 60 + Number(splitMinutes.split(':')[1]);
    // }

    // Function to get the count of items with keys starting with "codingquestionId_"
    const getCodingItemCount = () => {
        // let count = 0;
        // const foundIndicesObject = {};
        // // Iterate through local storage keys
        // for (let i = 0; i < localStorage.length; i++) {
        //     const key = localStorage.key(i);

        //     // Check if the key starts with "codingquestionId_"
        //     if (key && key.startsWith('codingquestionId_')) {
        //         count++;
        //         // localStorage.setItem(`language_${codingQuestionId}`, item.language);
        //         const index = codingAssessmentDetails.questionDetails.findIndex(item => item.codingQuestionId
        //             === key.split('_')[1]);
        //         foundIndicesObject[index + 1] = index + 1;
        //     }
        // }
        for (let i = 0; i < codingAssessmentDetails.questionDetails.length; i++) {
            const codingQuestionId = codingAssessmentDetails.questionDetails[i].codingQuestionId;

            // Use the key to check if it exists in local storage
            const localStorageKey = `codingquestionId_${codingQuestionId}`;

            if (localStorage.getItem(localStorageKey) !== null) {
                setProgress((prevProgress) => {
                    const updatedProgress = { ...prevProgress };
                    updatedProgress[codingQuestionId] = codingQuestionId;
                    return updatedProgress;
                });

                setCompletedQuestionNo((prevCompletedQuestionNo) => {
                    const updatedCompletedQuestionNo = { ...prevCompletedQuestionNo };
                    updatedCompletedQuestionNo[i + 1] = i + 1;
                    return updatedCompletedQuestionNo;
                });

            }
        }


        // Update the state with the count
        // setCodingItemCount(count);
        // setCompletedQuestionNo(foundIndicesObject);
    };

    useEffect(() => {

        // Call the function initially

        if (`startAssessmentStatus` in localStorage) {
            if (proctorDetails.includes('ENABLE_FULL_SCREEN') && user.role !== "CONSUMER_LEARNER") {
                setShowFullScreen(true);
            }
            getCodingItemCount();
        }
    }, [localStorage]); // Empty dependency array means this effect runs once on component mount

    // useEffect(() => {
    //     if (localStorage.getItem(`codingquestionId_${codingQuestionId}_compile`) !== null) {
    //         setUserAnswers(localStorage.getItem(`codingquestionId_${codingQuestionId}_compile`));
    //         const languageLabel = getLabelForLanguage(localStorage.getItem(`language_${codingQuestionId}`));
    //         if (languageLabel !== null) {
    //             setLang(languageLabel);
    //         }
    //     }
    //     else if (localStorage.getItem(`codingquestionId_${codingQuestionId}`) !== null) {

    //         setUserAnswers(localStorage.getItem(`codingquestionId_${codingQuestionId}`));
    //         const languageLabel = getLabelForLanguage(localStorage.getItem(`language_${codingQuestionId}`));
    //         if (languageLabel !== null) {
    //             setLang(languageLabel);
    //         }
    //     }
    //     else {
    //         setUserAnswers('');
    //     }

    // }, [codingQuestionId])

    const handlePermissionRequest = async () => {
        // if (user.role !== "CONSUMER_LEARNER") {
        try {
            // Try to get user media to implicitly request permissions for camera and microphone
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            // If successful, permissions were granted
            return true; // Permissions granted
        } catch (error) {
            // If error, permissions were denied or there was another issue accessing media
            console.error('Error accessing camera and microphone:', error);
            return false; // Permissions denied or error occurred
        }
        // }
        // else {
        //     return true;
        // }
    };


    // const handlePermissionRequest = async () => {
    //     // Check if camera and microphone permissions are already granted
    //     const cameraPermissionStatus = await navigator.permissions.query({ name: 'camera' });
    //     const microphonePermissionStatus = await navigator.permissions.query({ name: 'microphone' });

    //     if (cameraPermissionStatus.state === 'granted' && microphonePermissionStatus.state === 'granted') {
    //         // Permissions are already granted
    //         setCameraAndMicPermission(true);
    //     }
    //     else {
    //         alert("To access the assessment, please grant permission for camera and mic access.");
    //         // Toast.error({message: "Error requesting permissions:", time: 4000})
    //         setCameraAndMicPermission(false);
    //     }

    // };

    // useEffect(() => {
    //     // Delay the permission request by 4 seconds
    //     const timeoutId = setTimeout(() => {
    //         if (!cameraAndMicPermission) {
    //             handlePermissionRequest();
    //         }
    //     }, 4000);

    //     // Cleanup function to clear the timeout if the component unmounts
    //     return () => clearTimeout(timeoutId);
    // }, [cameraAndMicPermission]); // Include cameraAndMicPermission in the dependency array

    return (
        <>
            <ToastContainer />
            <div onKeyDown={handleKeyDown} tabIndex={0}>
                <div className='d-flex px-2' style={{ background: "#49167e", alignItems: "center" }}>
                    <div className='col-md-3' style={{ color: "#fff" }}>{
                        // Object.keys(progress).length !== 0 ?
                        `${Object.keys(progress).length} / ${codingAssessmentDetails.questionCount} Completed`
                        // :
                        // `${codingItemCount} / ${codingAssessmentDetails.questionCount} Completed`
                    }
                        {
                            (startAssessmentStatus.length !== 0 ||
                                `startAssessmentStatus` in localStorage) &&
                            <InfoIcon data-tooltip-id="submitQuestion" />
                        }
                        <LinearProgress variant="determinate" style={{ width: '40%' }} value={(Object.keys(progress).length / codingAssessmentDetails.questionCount) * 100} />

                        <Tooltip
                            id="submitQuestion"
                            place={"right"}
                            variant="info"
                        >
                            {
                                completedQuestionNo !== null ?

                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {
                                            Object.keys(completedQuestionNo).map(questionNo =>
                                                <span>{`Question ${questionNo}`}</span>
                                            )
                                        }

                                    </div>
                                    :
                                    'Submit Question First'
                            }

                        </Tooltip>
                    </div>
                    <div className='col-md-1' >
                        {
                            startAssessmentStatus.length !== 0 ||
                                `startAssessmentStatus` in localStorage ?

                                <CountdownTimer {...{
                                    timeLimit: codingAssessmentTimeOverInSeconds > 0 ? codingAssessmentDetails.duration - codingAssessmentTimeOverInSeconds
                                        : codingAssessmentDetails.duration,
                                    callback: () => { submitCodingAssessment() }
                                }} />
                                :
                                <PauseCountdownTimer {...{

                                    timeLimit: codingAssessmentTimeOverInSeconds > 0 ? codingAssessmentDetails.duration - codingAssessmentTimeOverInSeconds
                                        : codingAssessmentDetails.duration,
                                    callback: () => { }
                                }} />
                            // <div className='d-flex' style={{ width: "130px", padding: "15px 20px", color: "#fff", background: "#2d0a52" }}>
                            //     <IcnTimer />
                            //     <div className='mx-3'>
                            //         {`${Math.floor(codingAssessmentDetails.duration / 3600)}:${Math.floor((codingAssessmentDetails.duration % 3600) / 60)}:${codingAssessmentDetails.duration % 60}`}
                            //     </div>
                            // </div>
                        }

                    </div>

                    <div className='col-md-5 title-sm' style={{ color: "#fff", textAlign: "right", borderRight: "1px solid #fff" }}>{user.appuser.emailId}</div>

                    <div className='col-md-1'>
                        {/* {webcamRef !== null && (startAssessmentStatus.length !== 0 ||
                            `startAssessmentStatus` in localStorage) &&
                            user.role !== "CONSUMER_LEARNER" &&
                            <div style={{
                                width: "50px",
                                height: "50px",
                                overflow: "hidden",
                                // visibility: "hidden",
                                float: "inline-end"
                            }}>
                                <Webcam
                                    videoConstraints={videoConstraints}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    screenshotQuality={0.9}
                                    onUserMedia={handleUserMedia}
                                    onUserMediaError={handleUserMediaError}
                                    audio={false}
                                />
                            </div>

                        } */}
                    </div>

                    <div className='col-md-2'  >
                        {
                            startAssessmentStatus.length !== 0 ||
                                `startAssessmentStatus` in localStorage ?
                                <button className='btn btn-danger' style={{ float: "inline-end" }} onClick={() => setShowSubmitModal(true)}>End Test</button>
                                :
                                counter > 0 ?
                                    <div className='btn btn-danger' style={{ float: "inline-end" }}>{`Starting in ${counter} seconds`}</div>
                                    :
                                    <button className='btn btn-danger' style={{ float: "inline-end" }} onClick={startCodingAssessment}>Start Test</button>
                        }
                    </div>
                </div>



                {
                    faceDetections >= 2 ?
                        <BsModal {...{ show, setShow, headerTitle: "Attention: Multiple faces detected!", size: "md" }} />
                        :
                        steps === 'INTRO' ?
                            <>


                                <div className='dashboard-container' style={{ backgroundColor: handle.active && 'aliceblue' }}>
                                    <div className='d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <div className=" title-sm" style={{ cursor: "pointer" }} onClick={handleGoToTrainingDetails}>{ICN_BACK}Go back</div>
                                        <div className='title-md'>{codingAssessmentDetails.name}</div>

                                        <div style={{ textAlign: "right", display: "flex" }}>  <div className='title-sm p-2 ' style={{ borderRadius: "10px", background: "#EDEDED", textAlign: "center" }}>{`${codingAssessmentDetails.questionCount} questions`}</div>
                                            <div className='title-sm p-2 mx-2 ' style={{ borderRadius: "10px", background: "#EDEDED", textAlign: "center" }}>Total score: {codingAssessmentDetails.totalMarks}</div></div>
                                    </div>

                                    <div className='mt-3 mb-3 p-5' style={{ background: "#F7F8FA" }}>
                                        <div className='d-flex' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                            <div className='title-md'>{`${codingAssessmentDetails.questionCount} Programming questions`}</div>

                                            <div style={{ textAlign: "right", display: "flex" }}>  <div className='title-sm p-2  ' style={{ borderRadius: "10px", background: "#EDEDED", textAlign: "center" }}>Max score: {codingAssessmentDetails.totalMarks}</div></div>
                                        </div>
                                        <div className="catalog-container">
                                            {
                                                codingAssessmentDetails.questionDetails.map((res, index) => (
                                                    <div className="labList" style={{ height: "auto" }}>
                                                        <div className="labList-info flx5">
                                                            <div style={{
                                                                display: "flex", alignItems: "center", justifyContent: "space-between", cursor: (startAssessmentStatus.length !== 0 ||
                                                                    `startAssessmentStatus` in localStorage) && "pointer"
                                                            }}>
                                                                <div className="cat-title-md" onClick={() => {
                                                                    if (startAssessmentStatus.length !== 0 ||
                                                                        `startAssessmentStatus` in localStorage) {
                                                                        setSteps('QUESTION');
                                                                        setCurrentIndex(index);
                                                                        setQuestionName(res.question);
                                                                        setQuestionMark(res.marks);
                                                                        setQuestionDescription(res.description);
                                                                        setCodingQuestionId(res.codingQuestionId);
                                                                        setDatabaseQuestion(res.databaseQuestion);
                                                                        // if (!res.databaseQuestion) {

                                                                        //}
                                                                        if (`language_${res.codingQuestionId}` in localStorage) {
                                                                            const languageLabel = getLabelForLanguage(localStorage.getItem(`language_${res.codingQuestionId}`));
                                                                            if (languageLabel !== null) {
                                                                                setLang(languageLabel);
                                                                            }
                                                                        }
                                                                        getCodeTemplate(lang, res.codingQuestionId);

                                                                    }

                                                                }}>{index + 1} {res.question}</div>

                                                                <div style={{ textAlign: "right", display: "flex" }}>
                                                                    <div >
                                                                        <div className='title-sm p-2  ' style={{ borderRadius: "10px", background: "#EDEDED", textAlign: "center" }}>Score: + {res.marks}</div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                            </>
                            :
                            <>


                                <Split className='split' sizes={[5, 40, 55]} gutterSize={12}>
                                    <div className='mt-2  p-5' style={{
                                        background: "#ededed17",
                                        width: "2px",
                                        boxShadow: "rgb(136 136 136 / 9%) 5px 0px 5px -2px",

                                    }}>
                                        {
                                            user.role === "CONSUMER_LEARNER" &&

                                            <button className="btn mb-3" style={{ cursor: "pointer" }} onClick={handleGoToTrainingDetails}>{ICN_BACK}</button>
                                        }
                                        {
                                            codingAssessmentDetails.questionDetails.map((question, index) => {
                                                return (
                                                    <button
                                                        className={`mb-3 ${question.codingQuestionId in progress ||
                                                            `codingquestionId_${question.codingQuestionId}` in localStorage
                                                            ? 'activesuccesse'
                                                            : question.codingQuestionId === codingQuestionId ? 'activebtnnumber'
                                                                : 'compilebuttoncoding'}`}
                                                        style={{ borderRadius: "50%" }}
                                                        onClick={() => {
                                                            setchangeindex(index + 1)
                                                            if (`language_${index + 1}`) {

                                                                const languageLabel = getLabelForLanguage(localStorage.getItem(`language_${index + 1}`));
                                                                if (languageLabel !== null) {
                                                                    setLang(languageLabel);
                                                                }
                                                            }

                                                            if (question?.databaseQuestion === false) {
                                                                getCodeTemplate(lang, question.codingQuestionId);
                                                            }
                                                            handleQuestion(index, question.codingQuestionId)
                                                        }
                                                        }
                                                    >
                                                        {index + 1}
                                                    </button>
                                                )
                                            })
                                        }



                                    </div>
                                    <div className=' crollable-content-coding-assessment overflow-auto'>

                                        <div className='d-flex m-2 p-2' style={{ borderRadius: "10px", alignItems: "baseline", background: "#EDEDED", justifyContent: "space-between" }}> <div className='title-sm'>Question {currentIndex + 1}</div>
                                            <div className='title-sm'>Max score {questionMark}
                                            </div>

                                        </div>
                                        <hr />
                                        <div className='d-flex ' style={{ alignItems: "baseline", justifyContent: "space-between" }}>
                                            <div className='title-lg px-2'>{questionName}</div>
                                            {/* <div className="p-1" style={{ borderRadius: "10px", background: "#EDEDED" }} onClick={handle.enter}>{ICN_FULL_SCREEN}</div> */}
                                        </div>
                                        {/* <FullScreen handle={handle}> */}
                                        <div className={'codingassementquestiondescription'}>
                                            {
                                                isLoading ?
                                                    <SkeletonLoader skeletonCount={12.5} />
                                                    :
                                                    parse(questionDescription)}
                                        </div>
                                        {/* </FullScreen> */}
                                    </div>





                                    <div className=' mt-2'>
                                        <div style={{ background: "rgb(237, 237, 237)" }}>
                                            <div className="editor-tab ml-auto" style={{ background: "transparent", color: "black", justifyContent: "end" }}>
                                                {/* <div className='col-md-2 mt-2'>
<button className='btn btn-primary' style={{ borderRadius: "10px" }} onClick={handlePrevious} disabled={currentIndex === 0}>
Previous</button>
</div>
<div className='col-md-2 mt-2'>
<button className='btn btn-primary' style={{ borderRadius: "10px" }} onClick={handleNext}
disabled={currentIndex === codingAssessmentDetails.questionDetails.length - 1} >Next</button>
</div> */}
                                                <div className='p-2' style={{ background: "#fff", borderRadius: "10px" }}>
                                                    {
                                                        databaseQuestion ?
                                                            <Dropdown className="dropdown-menus">
                                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                                    <div className="editor-tab-w">Sql</div>
                                                                </Dropdown.Toggle>
                                                                {/* <Dropdown.Menu as="div" align="right">
            {Language.map(res => <Dropdown.Item onClick={() => { setLang(res); getCodeTemplate(res) }}>{res.label}</Dropdown.Item>)}
        </Dropdown.Menu> */}
                                                            </Dropdown>
                                                            :
                                                            // `language_${codingQuestionId}` in localStorage ?
                                                            // <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                            //         <div className="editor-tab-w">{localStorage.getItem(`language_${codingQuestionId}`)}</div>
                                                            //     </Dropdown.Toggle>
                                                            //     :
                                                            <Dropdown className="dropdown-menus">
                                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                                    <div className="editor-tab-w">{
                                                                        lang.label} {ICN_ARROW_DOWN}</div>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu as="div" align="right">
                                                                    {Language.map(res => <Dropdown.Item onClick={() => { setLang(res); getCodeTemplate(res, codingQuestionId) }}>{res.label}</Dropdown.Item>)}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                    }
                                                </div>

                                            </div>


                                            <>
                                                {isLoading ? (
                                                    // Loading message or spinner while waiting for changeindex to be fully updated
                                                    <SkeletonLoader skeletonCount={3} />
                                                ) : (
                                                    // Render the Editor or other components once changeindex is fully updated
                                                    <Editor
                                                        height="75vh"
                                                        defaultLanguage={lang.language}
                                                        theme="vs-dark"
                                                        value={editorValue}
                                                        onMount={handleEditorDidMount}
                                                        onChange={(value, event) => handleCodeChange(value)}
                                                    />
                                                )}
                                            </>




                                        </div>
                                        {/* <button onClick={spendTime}>Time</button> */}
                                        {
                                            isLoading ?
                                                <SkeletonLoader skeletonCount={3} />
                                                :

                                                <div className='d-flex mt-1 p-2' style={{ background: "#F3F4F8", alignItems: "center", justifyContent: 'space-between' }}>
                                                    {!databaseQuestion &&
                                                        <div className='col-md-5 title-sm' style={{ cursor: "pointer" }} onClick={handleButtonClick}>Test against Custom Input {ICN_ARROW_DOWN}</div>}

                                                    <div className='col-md-4' >

                                                        <button style={{ borderRadius: "10px" }} className='compilebuttoncoding' onClick={codingAssessmentCompileQuestion}>
                                                            {loadingCompile ?
                                                                <>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="md"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                        className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                        style={{ border: '5px dotted #49167E', width: "20px", height: "20px" }}
                                                                    />
                                                                    {' '}
                                                                    Compiling...
                                                                </>
                                                                :
                                                                'Compile & Test code'
                                                            }

                                                        </button>

                                                    </div>
                                                    <div className='col-md-3' >

                                                        <button className='btn btn-primary' style={{ float: "inline-end", borderRadius: "10px" }} onClick={codingAssessmentSubmitQuestion}>
                                                            {loadingSubmit ?
                                                                <>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="md"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                        className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                        style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                                                                    />
                                                                    {' '}
                                                                    Please wait, Initializing
                                                                </>
                                                                :
                                                                'Submit Code'}
                                                        </button>

                                                    </div>

                                                </div>
                                        }
                                        {showInput &&
                                            <div><textarea rows="5" placeholder="Enter input" className="form-control" onChange={(e) => setInputData(e.target.value)} /></div>
                                        }

                                        {
                                            submitoutput.length !== 0 &&
                                            <div><textarea rows="5" placeholder="Output" className="form-control" value={submitoutput} /></div>
                                        }






                                        {
                                            codingSubmissionResponse !== null ?

                                                <>

                                                    {
                                                        codingSubmissionResponse.testCaseEvaluationDetails !== null &&
                                                            codingSubmissionResponse.testCaseEvaluationDetails.filter(e => e.testCaseStatus === 'TLE' ||
                                                                e.testCaseStatus === 'FAILED').length > 0 ?
                                                            <div className='p-3 mt-2' style={{ background: "#FBE9E7", borderLeft: "5px solid red" }}>Result: <ClearIcon />Wrong Answer</div>
                                                            :
                                                            codingSubmissionResponse.databaseQuestionResponse !== null &&
                                                                !codingSubmissionResponse.databaseQuestionResponse.correct ?

                                                                <div className='p-3 mt-2' style={{ background: "#FBE9E7", borderLeft: "5px solid red" }}>Result: <ClearIcon />
                                                                    
                                                                        Wrong Answer</div>
                                                                :
                                                                // compileSqlResponse !== null &&
                                                                //     !compileSqlResponse.correct ?

                                                                //     <div className='p-3 mt-2' style={{ background: "#FBE9E7", borderLeft: "5px solid red" }}>Result: <ClearIcon />Wrong Answer</div>
                                                                //     :
                                                                <p className='p-3 mt-2' style={{ background: "#50C878", borderLeft: "5px solid red" }}>Result: <CheckIcon />Correct Answer</p>
                                                    }
                                                    <div className='row mt-2 mb-2'>
                                                        <div className='col-3'>
                                                            <div className='title-sm'>Score</div>
                                                            <div>{codingSubmissionResponse.score}</div>
                                                        </div>
                                                        {
                                                            codingSubmissionResponse.cpuTime !== null &&

                                                            <div className='col-3'>
                                                                <div className='title-sm'>Time (sec)</div>
                                                                <div >{codingSubmissionResponse.cpuTime.toFixed(3)}</div>
                                                            </div>
                                                        }
                                                        {
                                                            codingSubmissionResponse.memory !== null &&

                                                            <div className='col-3'>
                                                                <div className='title-sm'>Memory (KiB)</div>
                                                                <div >{codingSubmissionResponse.memory}</div>
                                                            </div>
                                                        }
                                                        {
                                                            codingSubmissionResponse.language !== null &&
                                                            <div className='col-3'>
                                                                <div className='title-sm'>Language</div>
                                                                <div>{codingSubmissionResponse.language}</div>
                                                            </div>

                                                        }
                                                    </div>
                                                    <hr />
                                                    {
                                                        codingSubmissionResponse.testCaseEvaluationDetails !== null &&
                                                        <DynamicTable  {...{ configuration, sourceData: codingSubmissionResponse.testCaseEvaluationDetails }} />

                                                    }
                                                    {
                                                        codingSubmissionResponse.databaseQuestionResponse !== null &&
                                                        <div>
                                                            {
                                                                ('details' in codingSubmissionResponse.databaseQuestionResponse?.userResponse ||
                                                                    'results' in codingSubmissionResponse.databaseQuestionResponse?.userResponse) &&
                                                                <>
                                                                    <div className='title-sm mt-2 mb-2'>User Response</div>
                                                                    <DynamicTable  {...{
                                                                        configuration: configuration1,
                                                                        sourceData: 'details' in codingSubmissionResponse.databaseQuestionResponse.userResponse ?
                                                                            [codingSubmissionResponse.databaseQuestionResponse.userResponse?.details] : codingSubmissionResponse.databaseQuestionResponse.userResponse.results
                                                                    }} />
                                                                </>
                                                            }
                                                            <div className='title-sm mt-2 mb-2'>Correct Response</div>
                                                            <DynamicTable  {...{ configuration: configuration2, sourceData: codingSubmissionResponse.databaseQuestionResponse.correctResponse.results }} />
                                                        </div>

                                                    }

                                                </>
                                                :

                                                compileSqlResponse !== null &&
                                                    !compileSqlResponse.correct ?

                                                    <div className='p-3 mt-2' style={{ background: "#FBE9E7", borderLeft: "5px solid red" }}>Result: <ClearIcon />
                                                        Wrong Answer</div>
                                                    :
                                                    compileSqlResponse !== null &&
                                                    <p className='p-3 mt-2' style={{ background: "#50C878", borderLeft: "5px solid red" }}>Result: <CheckIcon />Correct Answer</p>
                                        }
                                        {
                                            compileSqlResponse !== null &&
                                            <div>
                                                {
                                                    ('details' in compileSqlResponse.userResponse ||
                                                        'results' in compileSqlResponse.userResponse) &&
                                                    <>
                                                        <div className='title-sm mt-2 mb-2'>User Response</div>
                                                        <DynamicTable  {...{
                                                            configuration: configuration1, sourceData:
                                                                'details' in compileSqlResponse.userResponse ?
                                                                    [compileSqlResponse.userResponse?.details]
                                                                    :
                                                                    compileSqlResponse.userResponse.results
                                                        }} />
                                                    </>
                                                }

                                                <div className='title-sm mt-2 mb-2'>Correct Response</div>
                                                <DynamicTable  {...{ configuration: configuration2, sourceData: compileSqlResponse.correctResponse.results }} />
                                            </div>
                                        }
                                    </div>
                                </Split>
                                {/* <DynamicTable  {...{ configuration: configuration1, sourceData: country.results }} /> */}
                            </>

                }

                {/* <video crossOrigin='anonymous' ref={videoRef} height="1" width="1" autoPlay></video> */}
            </div>
            {/* {
                user.role !== "CONSUMER_LEARNER" &&
                <video crossOrigin='anonymous' ref={videoRef} height="1" width="1" autoPlay></video>
            } */}

            {showFullScreen &&
                <CodingModal {...{ show: showFullScreen, setShow: setShowFullScreen, headerTitle: "Please allow fullscreen mode?", size: "md" }}>
                    <div>

                        <div className="jce py20 mt20">
                            <Submit onClick={() => { enableFullscreen() }} style={{ backgroundColor: "#CECECE", color: "#333333", marginRight: "15px" }}>
                                Enable FullScreen
                            </Submit>
                        </div>
                    </div>
                </CodingModal>
            }
            {showSubmitModal && (
                <AlertDialog open={showSubmitModal} setOpen={setShowSubmitModal} title='Submit and Exit'
                    loadingStart={loadingStart}
                    body={`Once you submit " ${codingAssessmentDetails?.name} ", you won't be able to edit your response`}
                    apiMethod={submitCodingAssessment} />
            )}

        </>

    )
}

export default CodingAssessment